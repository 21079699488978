define("discourse/plugins/discourse-spoiler-alert/initializers/spoiler-alert", ["exports", "discourse/lib/plugin-api", "discourse/controllers/composer", "discourse/plugins/discourse-spoiler-alert/lib/apply-spoiler", "discourse/lib/to-markdown"], function (_exports, _pluginApi, _composer, _applySpoiler, _toMarkdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initializeSpoiler = initializeSpoiler;
  function spoil(element) {
    element.querySelectorAll(".spoiler").forEach(spoiler => {
      spoiler.classList.remove("spoiler");
      spoiler.classList.add("spoiled");
      (0, _applySpoiler.default)(spoiler);
    });
  }
  function initializeSpoiler(api) {
    api.decorateCookedElement(spoil, {
      id: "spoiler-alert"
    });
    api.addToolbarPopupMenuOptionsCallback(() => {
      return {
        action: "insertSpoiler",
        icon: "magic",
        label: "spoiler.title"
      };
    });
    _composer.default.reopen({
      actions: {
        insertSpoiler() {
          this.get("toolbarEvent").applySurround("[spoiler]", "[/spoiler]", "spoiler_text", {
            multiline: false,
            useBlockMode: true
          });
        }
      }
    });
    (0, _toMarkdown.addTagDecorateCallback)(function () {
      if (this.element.attributes.class === "spoiled") {
        this.prefix = "[spoiler]";
        this.suffix = "[/spoiler]";
      }
    });
    (0, _toMarkdown.addBlockDecorateCallback)(function (text) {
      const {
        name,
        attributes
      } = this.element;
      if (name === "div" && attributes.class === "spoiled") {
        this.prefix = "[spoiler]";
        this.suffix = "[/spoiler]";
        return text.trim();
      }
    });
  }
  var _default = _exports.default = {
    name: "spoiler-alert",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.spoiler_enabled) {
        (0, _pluginApi.withPluginApi)("1.3.0", initializeSpoiler);
      }
    }
  };
});